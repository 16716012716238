import React from "react";
import './Footer.css'
const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-content">
          © 2024 Copyright:
        </div>
      </footer>
    </>
  );
};

export default Footer;
